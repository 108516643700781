<template>
  <be-teleport
    v-if="useShow || !isHidden"
    v-show="!useShow || !isHidden"
    to="body"
    :disabled="noTeleport"
  >
    <div :id="modalOuterId" :style="modalOuterStyles" @mousedown="onClickOut">
      <transition
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @after-enter="onAfterEnter"
        @before-leave="onBeforeLeave"
        @leave="onLeave"
        @after-leave="onAfterLeave"
      >
        <div
          v-show="isVisible"
          v-bind="computedModalAttrs"
          ref="modal"
          :class="modalClasses"
          :style="modalStyles"
          @keydown="onEscape"
        >
          <div ref="dialog" :class="dialogClasses">
            <!-- Tab Trap -->
            <span tabindex="0" />

            <div
              ref="content"
              :class="['modal-content', contentClass]"
              tabindex="-1"
            >
              <header
                v-if="!noHeader"
                ref="header"
                :class="[
                  'modal-header',
                  'align-items-center',
                  'border-bottom-1',
                  headerClass,
                ]"
              >
                <slot name="header" v-bind="slotScope">
                  <component :is="titleTag" :class="titleClasses">
                    <slot name="title" v-bind="slotScope">
                      {{ title }}
                    </slot>
                  </component>
                </slot>

                <button
                  v-if="!noHeaderCloseButton"
                  ref="close-button"
                  type="button"
                  :disabled="isTransitioning || null"
                  :aria-label="localHeaderCloseLabel"
                  class="close mr-n1 d-print-none"
                  @click="onClose"
                >
                  <i class="fas fa-2xs fa-times" />
                </button>
              </header>

              <div ref="body" :class="['modal-body', bodyClass]">
                <slot v-bind="slotScope" />
              </div>

              <footer
                v-if="!noFooter"
                ref="footer"
                :class="['modal-footer gap-2 border-top-1', footerClass]"
              >
                <slot name="footer" v-bind="slotScope">
                  <be-button
                    v-if="!okOnly"
                    ref="cancel-button"
                    :disabled="cancelDisabled || isTransitioning"
                    :size="buttonSize"
                    :variant="cancelVariant"
                    @click="onCancel"
                  >
                    <slot name="cancel-button">
                      {{ localCancelTitle }}
                    </slot>
                  </be-button>

                  <be-button
                    ref="ok-button"
                    :disabled="okDisabled || isTransitioning"
                    :size="buttonSize"
                    :variant="computedVariant || okVariant"
                    @click="onOk"
                  >
                    <slot name="ok-button">
                      {{ localOkTitle }}
                    </slot>
                  </be-button>
                </slot>
              </footer>
            </div>

            <!-- Tab Trap -->
            <span tabindex="0" />
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-show="isVisible" class="modal-backdrop">
          <slot name="backdrop" />
        </div>
      </transition>
    </div>
  </be-teleport>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapMutations } from "vuex";
import { generateId } from "@/utils/id";
import { KEY_CODE_ESCAPE } from "@/constants/key-codes";
import { helpers } from "@/store/modules/modals";
import modalsMixin from "@/mixins/modals";
import { BeModalEvent } from "@/helpers/be-modal-event";

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

export default {
  name: "BeModal",

  mixins: [modalsMixin],

  props: {
    ariaLabel: {
      type: String,
      required: false,
      default: undefined,
    },

    autoFocus: {
      type: Boolean,
      required: false,
      default: true,
    },

    autoFocusButton: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        return ["ok", "cancel", "close"].includes(value);
      },
    },

    bodyClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    buttonSize: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        return ["sm", "lg"].includes(value);
      },
    },

    cancelDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    cancelTitle: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.cancel")
    },

    cancelVariant: {
      type: String,
      required: false,
      default: "light",
    },

    centered: {
      type: Boolean,
      required: false,
      default: false,
    },

    contentClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    dialogClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    footerClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    headerClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    headerCloseLabel: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.close")
    },

    id: {
      type: String,
      required: false,
      default: generateId("be-modal"),
    },

    modalClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    noCloseOnBackdrop: {
      type: Boolean,
      required: false,
      default: false,
    },

    noCloseOnEscape: {
      type: Boolean,
      required: false,
      default: false,
    },

    noFooter: {
      type: Boolean,
      required: false,
      default: false,
    },

    noHeader: {
      type: Boolean,
      required: false,
      default: false,
    },

    noHeaderCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    noStacking: {
      type: Boolean,
      required: false,
      default: false,
    },

    noTeleport: {
      type: Boolean,
      required: false,
      default: false,
    },

    okDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    okOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    okTitle: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.titles.ok")
    },

    okVariant: {
      type: String,
      required: false,
      default: "primary",
    },

    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: "md",

      validator: (value) => {
        return ["sm", "md", "lg", "xl"].includes(value);
      },
    },

    title: {
      type: String,
      required: false,
      default: undefined,
    },

    titleClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    titleSrOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    titleTag: {
      type: String,
      required: false,
      default: "h5",
    },

    variant: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        return ["primary", "warning", "danger", "success", "info"].includes(
          value
        );
      },
    },

    visible: {
      type: Boolean,
      required: false,
      default: false,
    },

    useShow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    "update:visible",
    "cancel",
    "close",
    "hide",
    "hidden",
    "ok",
    "show",
    "shown",
  ],

  data() {
    return {
      hasOpenSelect: false,
      isHidden: true,
      isVisible: false,
      isShow: false,
      isOpening: false,
      isClosing: false,
      isTransitioning: false,
      isBlock: false,
      isModalOverflowing: false,
    };
  },

  computed: {
    bodyId() {
      return generateId("be-modal-body");
    },

    computedModalAttrs() {
      const { ariaLabel, bodyId, isVisible, noHeader, title, titleId } = this;

      return {
        id: this.id,
        role: "dialog",
        "aria-hidden": isVisible ? null : "true",
        "aria-modal": isVisible ? "true" : null,
        "aria-label": ariaLabel,
        "aria-describedby": bodyId,

        "aria-labelledby":
          noHeader || ariaLabel || !(this.$slots.title || title)
            ? null
            : titleId,
      };
    },

    computedVariant() {
      const { variant } = this;
      const validVariants = ["primary", "warning", "danger", "success", "info"];

      return validVariants.includes(variant) ? variant : null;
    },

    dialogClasses() {
      const { centered, dialogClass, scrollable, size } = this;

      return [
        "modal-dialog",
        {
          [`modal-${size}`]: size,
          "modal-dialog-centered": centered,
          "modal-dialog-scrollable": scrollable,
        },
        dialogClass,
      ];
    },

    localCancelTitle() {
      if (this.cancelTitle === undefined) {
        return this.$t("buttons.titles.cancel");
      }

      return this.cancelTitle;
    },

    localHeaderCloseLabel() {
      if (this.headerCloseLabel === undefined) {
        return this.$t("buttons.titles.close");
      }

      return this.headerCloseLabel;
    },

    localOkTitle() {
      if (this.okTitle === undefined) {
        return this.$t("buttons.titles.ok");
      }

      return this.okTitle;
    },

    modalClasses() {
      return [
        "be-modal",
        "modal",
        "fade",
        {
          show: this.isShow,
        },
        this.modalClass,
      ];
    },

    modalStyles() {
      const { isBlock, isBodyOverflowing, isModalOverflowing, scrollbarWidth } =
        this;

      const sbWidth = `${scrollbarWidth}px`;

      return {
        paddingLeft: !isBodyOverflowing && isModalOverflowing ? sbWidth : null,
        paddingRight: isBodyOverflowing && !isModalOverflowing ? sbWidth : null,
        display: isBlock ? "block" : "none",
      };
    },

    modalOuterId() {
      return generateId("be-modal-outer");
    },

    modalOuterStyles() {
      // Needed for proper stacking of modals
      return {
        position: "absolute",
        zIndex: this.zIndex,
      };
    },

    slotScope() {
      return {
        cancel: this.onCancel,
        close: this.onClose,
        hide: this.hide,
        ok: this.onOk,
        visible: this.isVisible,
      };
    },

    titleClasses() {
      const { titleClass, titleSrOnly } = this;

      return [
        "modal-title",
        {
          "sr-only": titleSrOnly,
        },
        titleClass,
      ];
    },

    titleId() {
      return generateId("be-modal-title");
    },
  },

  watch: {
    visible(newValue, oldValue) {
      if (newValue !== oldValue) {
        this[newValue ? "show" : "hide"]();
      }
    },
  },

  mounted() {
    // Set initial z-index in modals store
    this.setBaseZIndex();

    // Set initial z-index as queried from the DOM
    this.zIndex = helpers.calculateBaseZIndex();

    // Listen for events from others to show/hide this modal
    EventBus.on("be::show::modal", this.showHandler);
    EventBus.on("be::hide::modal", this.hideHandler);
    EventBus.on("be::toggle::modal", this.toggleHandler);

    // Listen for select open/close events
    EventBus.on("be::select::open", () => {
      this.hasOpenSelect = true;
    });
    EventBus.on("be::select::close", () => {
      this.hasOpenSelect = false;
    });

    if (this.visible) {
      this.$nextTick(this.show);
    }
  },

  beforeUnmount() {
    this.unregister(this);

    if (this.isVisible) {
      EventBus.off("be::show::modal", this.showHandler);
      EventBus.off("be::hide::modal", this.hideHandler);
      EventBus.off("be::toggle::modal", this.toggleHandler);

      this.isVisible = false;
      this.isShow = false;
      this.isTransitioning = false;
    }
  },

  methods: {
    ...mapMutations("modals", ["setBaseZIndex"]),

    show() {
      // If already visible, or opening, do nothing
      if (this.isVisible || this.isOpening) {
        return;
      }

      // If closing, wait until hidden before re-opening
      if (this.isClosing) {
        EventBus.once("be::modal::hidden", (id) => {
          if (id === this.id) {
            this.show();
          }
        });
        return;
      }

      // If another modal is already opened and stacking is not allowed,
      // wait until hidden before opening
      if (this.modalsAreOpen && this.noStacking) {
        EventBus.once("be::modal::hidden", (id) => {
          if (id === this.id) {
            this.show();
          }
        });
        return;
      }

      // Register modal
      this.registerModal(this);

      // Set opening flag
      this.isOpening = true;

      // Store current focused element for later restoration
      this.$_returnFocus = document.activeElement;

      // Build show event
      const showEvent = this.buildEvent("show", { cancelable: true });

      // Emit show event
      this.emitEvent(showEvent);

      // If canceled, don't show modal
      if (showEvent.defaultPrevented) {
        this.isOpening = false;
        this.updateModel(false);
        return;
      }

      // Render modal to DOM
      this.isHidden = false;

      // Show modal in next tick to ensure DOM is ready
      this.$nextTick(() => {
        this.isVisible = true;
        this.isOpening = false;
        this.updateModel(true);
      });
    },

    hide(emittedEvent) {
      // If already hidden, or closing, do nothing
      if (!this.isVisible || this.isClosing) {
        return;
      }

      // Set closing flag
      this.isClosing = true;

      // Build hide event
      const hideEvent = this.buildEvent("hide", {
        cancelable: true,
        trigger: emittedEvent || null,
      });

      // Emit the given event if provided (e.g. "ok", "cancel", "close")
      if (emittedEvent) {
        this.$emit(emittedEvent, hideEvent);
      }

      // Emit hide event
      this.emitEvent(hideEvent);

      // If canceled, don't hide modal
      if (hideEvent.defaultPrevented) {
        this.isClosing = false;
        this.updateModel(true);
        return;
      }

      // Hide modal, clean up is handled in `onAfterLeave`
      this.isVisible = false;
    },

    toggle(triggerElement) {
      if (triggerElement) {
        this.$_returnFocus = triggerElement;
      }

      if (this.isVisible) {
        this.hide();
      } else {
        this.show();
      }
    },

    updateModel(value) {
      if (value !== this.visible) {
        this.$emit("update:visible", value);
      }
    },

    checkModalOverflow() {
      if (this.isVisible) {
        const modal = this.$refs.modal;
        this.isModalOverflowing =
          modal.scrollHeight > document.documentElement.clientHeight;
      }
    },

    setResizeEvent(on) {
      const action = on ? "addEventListener" : "removeEventListener";

      window[action]("resize", this.checkModalOverflow);
      window[action]("orientationchange", this.checkModalOverflow);
    },

    handleFocus() {
      // If `autoFocusButton` prop is set, focus the button
      if (this.autoFocusButton) {
        const button = this.$refs[`${this.autoFocusButton}-button`];

        if (button) {
          button.focus();
        }
      } else if (this.autoFocus) {
        // Try to focus the first input element
        const input = this.$refs.content.querySelector(
          "input:not([disabled]):not([type=hidden]), textarea:not([disabled]), select:not([disabled]), .be-form-custom-select-button:not([disabled])"
        );

        if (input) {
          input.focus();
        } else {
          // If no input, focus modal itself
          this.$refs.content.focus();
        }
      } else {
        // Always focus modal itself if autoFocus is false
        this.$refs.content.focus();
      }
    },

    buildEvent(type, options = {}) {
      return new BeModalEvent(type, {
        cancelable: false,
        target: this.$refs.modal || this.$el || null,
        relatedTarget: null,
        trigger: null,
        ...options,
        vueTarget: this,
        componentId: this.id,
      });
    },

    emitEvent(beEvent) {
      const { type } = beEvent;

      EventBus.emit(`be::modal::${type}`, beEvent, beEvent.componentId);
      this.$emit(type, beEvent);
    },

    onBeforeEnter() {
      this.isTransitioning = true;
      this.setResizeEvent(true);
    },

    onEnter() {
      this.isBlock = true;

      // We add the `show` class 1 frame later
      requestAnimationFrame(() => {
        // We need two calls to be sure that one frame has passed
        // since `requestAnimationFrame` is executed before a repaint
        requestAnimationFrame(() => {
          this.isShow = true;
        });
      });
    },

    onAfterEnter() {
      this.checkModalOverflow();
      this.isTransitioning = false;

      this.$nextTick(() => {
        this.handleFocus();
        this.emitEvent(this.buildEvent("shown"));
      });
    },

    onBeforeLeave() {
      this.isTransitioning = true;
      this.setResizeEvent(false);
    },

    onLeave() {
      this.isShow = false;
    },

    onAfterLeave() {
      this.isBlock = false;
      this.isModalOverflowing = false;
      this.isTransitioning = false;
      this.isHidden = true;

      this.$nextTick(() => {
        this.isClosing = false;
        this.unregister(this);
        this.updateModel(false);
        this.emitEvent(this.buildEvent("hidden"));

        // Return focus to original element
        if (this.$_returnFocus) {
          this.$_returnFocus.focus();
          this.$_returnFocus = null;
        }
      });
    },

    onClickOut(event) {
      // Do nothing if:
      // - not visible
      // - backdrop click disabled
      // - has open select
      // - clicked element is not in DOM
      if (
        !this.isVisible ||
        this.noCloseOnBackdrop ||
        this.hasOpenSelect ||
        !document.body.contains(event.target)
      ) {
        return;
      }

      // If click was outside of dialog, hide modal
      if (this.isVisible && !this.$refs.dialog.contains(event.target)) {
        this.hide("close");
      }
    },

    onOk() {
      this.hide("ok");
    },

    onCancel() {
      this.hide("cancel");
    },

    onClose() {
      this.hide("close");
    },

    onEscape(event) {
      if (
        this.isVisible &&
        !this.noCloseOnEscape &&
        !this.hasOpenSelect &&
        event.keyCode === KEY_CODE_ESCAPE
      ) {
        this.hide();
      }
    },

    showHandler(id, triggerElement) {
      // If the id matches, show this modal
      if (id === this.id) {
        this.$_returnFocus = triggerElement || document.activeElement;
        this.show();
      }

      // If another modal is opened, close this one if stacking is not allowed
      if (this.noStacking && id !== this.id && this.isVisible) {
        this.hide();
      }
    },

    hideHandler(id) {
      if (id === this.id) {
        this.hide();
      }
    },

    toggleHandler(id, triggerElement) {
      if (id === this.id) {
        this.toggle(triggerElement);
      }
    },
  },
};
</script>
